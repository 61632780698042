/* eslint-disable react-hooks/exhaustive-deps*/

import { useContext, useEffect, useRef, useState } from "react";
import ImageUploading from "react-images-uploading";
import Select, { createFilter } from "react-select";
import GlobalContext from "../../context/GlobalContext/GlobalContext";

const FormAsal = ({ data, setData, title }) => {
  const { provinsiList, getProvinsiList } = useContext(GlobalContext);
  useEffect(() => {
    getProvinsiList();
  }, []);

  return (
    <>
      <p className="font-subtitle w-full text-center font-content-0">
        Masukkan {title} baru kamu
      </p>
      <div className="w-full flex border-2 border-black rounded-xl items-center select-container">
        <Select
          placeholder={title[0].toUpperCase() + title.slice(1) + " baru"}
          styles={{
            control: () => ({
              alignItems: "center",
              border: "none",
              display: "flex",
              flexWrap: "wrap",
              WebkitBoxPack: "justify",
              justifyContent: "space-between",
              minHeight: "38px",
              position: "relative",
            }),
          }}
          className="w-full border-none outline-none font-content-1"
          onChange={(val) => {
            setData(val.label);
          }}
          options={provinsiList.map((item) => {
            return { value: item, label: item };
          })}
          filterOption={createFilter({ ignoreAccents: false })}
        />
      </div>
    </>
  );
};

const FormSekolah = ({ data, setData, title }) => {
  const { provinsiList, sekolahList, getProvinsiList, getSekolahList } =
    useContext(GlobalContext);
  useEffect(() => {
    getProvinsiList();
  }, []);
  const [currentProv, setCurrentProv] = useState("");

  const DynamicSelect = ({ option }) => {
    const options = option.map((item) => {
      return { value: item.npsn, label: item.nama };
    });
    const MenuList = function MenuList(props) {
      const children = props.children;

      if (!children.length) {
        return <>{children}</>;
      }

      return (
        <>
          {children.length &&
            children.map((key) => {
              delete key.props.innerProps.onMouseMove; //FIX LAG!!
              delete key.props.innerProps.onMouseOver; //FIX LAG!!

              return key;
            })}
        </>
      );
    };

    return (
      <>
        <Select
          placeholder="Asal sekolah"
          styles={{
            control: () => ({
              alignItems: "center",
              border: "none",
              display: "flex",
              flexWrap: "wrap",
              WebkitBoxPack: "justify",
              justifyContent: "space-between",
              minHeight: "38px",
              position: "relative",
            }),
            menu: (provided, state) => ({
              ...provided,
              height: "13rem",
              overflow: "auto",
            }),
          }}
          className="w-full border-none outline-none font-content-1"
          onChange={(val) => {
            setData({
              id: val.value,
              nama: val.label,
            });
          }}
          options={options}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            MenuList,
          }}
          value={options.find((item) => item.value === data.id)}
        />
      </>
    );
  };

  return (
    <>
      <p className="font-subtitle w-full text-center font-content-0">
        Masukkan {title} baru kamu
      </p>
      <div className="w-full flex border-2 border-black rounded-xl items-center select-container">
        <Select
          placeholder={"Provinsi sekolah baru"}
          styles={{
            control: () => ({
              alignItems: "center",
              border: "none",
              display: "flex",
              flexWrap: "wrap",
              WebkitBoxPack: "justify",
              justifyContent: "space-between",
              minHeight: "38px",
              position: "relative",
            }),
          }}
          className="w-full border-none outline-none font-content-1"
          onChange={(val) => {
            if (val.value !== currentProv) {
              getSekolahList(val.value);
              setCurrentProv(val.value);
            }
          }}
          options={provinsiList.map((item) => {
            return { value: item, label: item };
          })}
          filterOption={createFilter({ ignoreAccents: false })}
        />
      </div>
      <div className="w-full flex flex-col items-center gap-1">
        <div className="w-full flex flex-col border-2 border-black rounded-xl items-center">
          <DynamicSelect option={sekolahList} currentValue={data} />
        </div>
        <div className="w-full text-xs text-grey-300 text-center">
          Pilih "Lainnya" jika sekolahmu tidak ada di daftar
        </div>
      </div>
    </>
  );
};

const FormPassword = ({
  data,
  setData,
  title,
  setOpen,
  setNewData,
  newData,
}) => {
  const [passwordState, setPasswordState] = useState(true);
  const confirmPassRef = useRef(<></>);
  return (
    <>
      <p className="font-subtitle w-full text-center font-content-0">
        Masukkan {title} baru kamu
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (confirmPassRef.current.value === data) {
            confirmPassRef.current.setCustomValidity("");
            setNewData({ ...newData, [title]: data });
            setOpen(false);
          } else {
            confirmPassRef.current.setCustomValidity("Password tidak cocok");
            confirmPassRef.current.reportValidity();
          }
        }}
        className="flex flex-col gap-3 w-full"
      >
        <div className="w-full flex border-2 border-black rounded-xl items-center">
          <input
            autoFocus={true}
            className="w-full-5 sm:w-full-10 px-3 py-2 rounded-l-xl border-none outline-none bg-white font-content-1"
            placeholder={"Password baru"}
            value={data}
            type={passwordState ? "password" : "text"}
            onChange={(e) => {
              setData(e.target.value);
              if (confirmPassRef.current.value === e.target.value) {
                confirmPassRef.current.setCustomValidity("");
              }
            }}
            autoComplete="false"
            required
          />
          <i
            onClick={() => {
              setPasswordState(!passwordState);
            }}
            className={`w-5 sm:w-10 text-abu fas ${
              passwordState ? "fa-eye-slash" : "fa-eye"
            } text-lg md:text-xl rounded-full cursor-pointer text-center`}
          ></i>
        </div>
        <div className="w-full flex border-2 border-black rounded-xl items-center">
          <input
            className="w-full-5 sm:w-full-10 px-3 py-2 rounded-l-xl border-none outline-none bg-white font-content-1"
            placeholder={"Ulangi password baru"}
            type={passwordState ? "password" : "text"}
            autoComplete="false"
            ref={confirmPassRef}
            onChange={(e) => {
              const password = e.target.value;
              if (password !== data) {
                e.target.setCustomValidity("Password tidak cocok");
                e.target.reportValidity();
              } else {
                e.target.setCustomValidity("");
              }
            }}
            required
          />
          <i
            onClick={() => {
              setPasswordState(!passwordState);
            }}
            className={`w-5 sm:w-10 text-abu fas ${
              passwordState ? "fa-eye-slash" : "fa-eye"
            } text-lg md:text-xl rounded-full cursor-pointer text-center`}
          ></i>
        </div>
        <div className="grid grid-rows-1 grid-cols-2 gap-3 place-self-center">
          <button
            type="submit"
            className="text-center bg-oren text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
          >
            OK
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="text-center bg-krem text-black rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const FormInput = ({ data, setData, title, setOpen, setNewData, newData }) => {
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    if (allowSubmit) {
      setNewData({ ...newData, [title]: data });
      setOpen(false);
    }
  }, [allowSubmit]);
  return (
    <>
      <p className="font-subtitle w-full text-center font-content-0">
        Masukkan {title} baru kamu
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (title === "nama") {
            const nameList = data.split(" ");
            const titleCaseName = nameList.map((name) => {
              if (name.length) {
                const newName =
                  name[0].toUpperCase() + name.slice(1).toLowerCase();
                return newName;
              } else {
                return name;
              }
            });
            setData(
              titleCaseName
                .join(" ")
                .trim()
                .replace(/ +(?= )/g, "")
            );
          } else if (title === "email") {
            setData(data.toLowerCase().replace(/ /g, ""));
          }
          setAllowSubmit(true);
        }}
        className="flex flex-col gap-3 w-full"
      >
        <div className="w-full flex border-2 border-black rounded-xl items-center">
          <input
            autoFocus={true}
            className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
            placeholder={title[0].toUpperCase() + title.slice(1) + " baru"}
            type={title === "email" ? "email" : "text"}
            onChange={(e) => setData(e.target.value)}
            required
          />
        </div>
        <div className="grid grid-rows-1 grid-cols-2 gap-3 place-self-center">
          <button
            type="submit"
            className="text-center bg-oren text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
          >
            OK
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="text-center bg-krem text-black rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const FormHP = ({ data, setData, title, setOpen, setNewData, newData }) => {
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    if (allowSubmit) {
      if (data.length > 2)
        if (data.substr(0, 2) === "08") {
          setNewData({ ...newData, [title]: data });
          setOpen(false);
        } else {
          inputRef.current.setCustomValidity("Format tidak valid.");
          inputRef.current.reportValidity();
          setAllowSubmit(false);
        }
      else {
        inputRef.setCustomValidity("Format tidak valid.");
        inputRef.current.reportValidity();
        setAllowSubmit(false);
      }
    }
  }, [allowSubmit]);
  const inputRef = useRef(<></>);
  return (
    <>
      <p className="font-subtitle w-full text-center font-content-0">
        Masukkan no HP baru kamu
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setAllowSubmit(true);
        }}
        className="flex flex-col gap-3 w-full"
      >
        <div className="w-full flex border-2 border-black rounded-xl items-center">
          <input
            autoFocus={true}
            className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
            placeholder="08xxxxxxxxxxx"
            type="tel"
            pattern="[0-9]{8,15}"
            onChange={(e) => {
              inputRef.current.setCustomValidity("");
              let valid = true;
              for (let i = 0; i < e.target.value.length; i++) {
                const char = e.target.value[i];
                if (isNaN(parseInt(char))) valid = false;
              }
              if (valid) setData(e.target.value);
            }}
            value={data}
            ref={inputRef}
            required
          />
        </div>
        <div className="grid grid-rows-1 grid-cols-2 gap-3 place-self-center">
          <button
            type="submit"
            className="text-center bg-oren text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
          >
            OK
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="text-center bg-krem text-black rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const FormPhoto = ({ data, setData, title, setOpen, setNewData, newData }) => {
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    setData([]);
  }, [setData]);

  useEffect(() => {
    if (allowSubmit) {
      if (data[0]) setNewData({ ...newData, [title]: data[0] });
      setOpen(false);
    }
  }, [allowSubmit]);
  const onChange = function (imageList, addUpdateIndex) {
    // data for submit
    if (imageList.length) {
      setData([imageList[addUpdateIndex[0]]]);
    } else {
      setData([]);
    }
  };
  return (
    <>
      <p className="font-subtitle w-full text-center font-content-0">
        Masukkan foto baru kamu
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="flex flex-col gap-3 w-full"
      >
        <div className="w-full flex border-2 border-black rounded-xl items-center">
          <ImageUploading
            multiple
            value={data}
            onChange={onChange}
            maxNumber={2}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              isDragging,
              dragProps,
            }) => {
              return (
                // write your building UI
                <div className="w-full p-2">
                  {!imageList.length ? (
                    <div
                      className="rounded-xl flex flex-col text-center items-center justify-center w-full py-12 font-content-1"
                      style={isDragging ? { border: "1px solid black" } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <i className="fa-solid fa-upload text-3xl"></i>
                      Klik atau tarik gambar ke sini (max 2MB)
                    </div>
                  ) : (
                    imageList.map((image, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center gap-2"
                      >
                        <img src={image.dataURL} alt="profile" />
                        <div>
                          <button
                            className="text-center bg-merah text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
                            onClick={() => onImageRemoveAll()}
                          >
                            Hapus
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              );
            }}
          </ImageUploading>
        </div>
        <div className="grid grid-rows-1 grid-cols-2 gap-3 place-self-center">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setAllowSubmit(true);
            }}
            className="text-center bg-oren text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
          >
            OK
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="text-center bg-krem text-black rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
          >
            Batal
          </button>
        </div>
      </form>
    </>
  );
};

const ChangeDataComponent = ({ title, setOpen, newData, setNewData }) => {
  const [data, setData] = useState("");

  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h6 className="font-bold text-center">Ubah Data</h6>
        <div className="flex flex-col gap-3 w-full">
          {title === "asal" ? (
            <FormAsal {...{ data, setData, title }} />
          ) : title === "sekolah" ? (
            <FormSekolah {...{ data, setData, title }} />
          ) : title === "password" ? (
            <FormPassword
              {...{ data, setData, title, setOpen, setNewData, newData }}
            />
          ) : title === "foto_profil" ? (
            <FormPhoto
              {...{ data, setData, title, setOpen, setNewData, newData }}
            />
          ) : title === "no_hp" ? (
            <FormHP
              {...{ data, setData, title, setOpen, setNewData, newData }}
            />
          ) : (
            <FormInput
              {...{ data, setData, title, setOpen, setNewData, newData }}
            />
          )}
        </div>

        {(title === "asal" || title === "sekolah") && (
          <div className="grid grid-rows-1 grid-cols-2 gap-3 place-self-center">
            <button
              onClick={() => {
                if (data) {
                  setNewData({ ...newData, [title]: data });
                  setOpen(false);
                }
              }}
              className="text-center bg-oren text-white rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
            >
              OK
            </button>
            <button
              onClick={() => setOpen(false)}
              type="button"
              className="text-center bg-krem text-black rounded-xl flex-grow px-4 py-1 font-content-0 font-bold"
            >
              Batal
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ChangeDataComponent;
